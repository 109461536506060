import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ["menu", "openIcon", "closeIcon"]

  connect() {
    console.log("Mobile menu controller connected.", this.element)
    console.log('menuTarget', this.menuTarget)
    console.log('openIconTarget', this.openIconTarget)
    console.log('closeIconTarget', this.closeIconTarget)
  }

  toggleMenu(event) {
    console.log('toggle mobile menu')
    console.log(event.target)
    this.menuTarget.classList.toggle('hidden');
    this.openIconTarget.classList.toggle('hidden');
    this.closeIconTarget.classList.toggle('hidden');
  }
}
