import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    wait: { type: Number, default: 300 }
  }

  connect() {
    console.log("connecting to debounce controller", this.element)
  }

  debouncedSubmit() {
    clearTimeout(this.timeoutId)

    this.timeoutId = setTimeout(() => {
      this.element.requestSubmit()
    }, this.waitValue)
  }
}

