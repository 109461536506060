// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers/index.js"
import "trix"
import "@rails/actiontext"

window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}
