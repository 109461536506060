import { BridgeComponent, BridgeElement } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "form"
  static targets = ["submit"]

  connect() {
    console.log("connecting form bridge component", this)
    super.connect()
    this.#notifyBridgeOfConnect()
  }

  submitStart(event) {
    this.submitTarget.disabled = true
    this.send("submitDisabled")
  }

  submitEnd(event) {
    this.submitTarget.disabled = false
    this.send("submitEnabled")
  }

  #notifyBridgeOfConnect() {
    // console.log("BridgeComponent#notifyBridgeOfConnect")
    const submitButton = new BridgeElement(this.submitTarget)
    // console.log("submitButton.bridgeAttribute('title')", submitButton.bridgeAttribute("title"))
    const submitTitle = submitButton.bridgeAttribute("title") || submitButton.title

    this.send("connect", {submitTitle}, () => {
      this.submitTarget.click()
    })
  }
}
