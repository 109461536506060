import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]


  connect() {
    console.log("connecting to geolocation controller", this.element)
  }


  handleClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.handleSuccess.bind(this),
        this.handleError.bind(this)
      )
    } else {
      console.error("Geolocation is not supported by this browser.")
    }
  }

  handleSuccess(position) {
    const { latitude, longitude } = position.coords
    this.sendLocationToRails(latitude, longitude)
  }

  handleError(error) {
    console.error(`Geolocation error: ${error.message}`)
  }

  sendLocationToRails(latitude, longitude) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    const url = `/locate?latitude=${latitude}&longitude=${longitude}`;
    Turbolinks.visit(url);
    // fetch(url, {
    //   method: "GET",
    //   headers: {
    //     "X-CSRF-Token": csrfToken,
    //   },
    // });
   
    // fetch("/locate", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-CSRF-Token": csrfToken,
    //   },
    //   body: JSON.stringify({ latitude, longitude }),
    // });
  }
}