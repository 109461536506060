import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="locations"
export default class extends Controller {
  static targets = ["map"]
  static values = {
    gmid: String, default: 'barfoo'
  }
  
  connect() {
    console.log("LocationsController connected")
    // console.log("this.mapTarget", this.mapTarget)
    // console.log("map_id", this.gmidValue)

    if (window.google) {
      console.log("calling this.initGoogle()")
      this.initGoogle();
    }
  }

  initGoogle() {
    console.log('init google')
    var bounds = new google.maps.LatLngBounds();
    var infoWindow = new google.maps.InfoWindow;
    var location_json = JSON.parse(this.mapTarget.dataset.locations)
    // console.log('location_json', location_json)
    var template = '\
      <div class="infowindow h-58 w-72">\
        <div class="w-full h-full py-4 px-4 text-gray-800 flex flex-col justify-between">\
          <div class="flex-1">\
            <h3 class="mt-1 mb-4 font-semibold text-xl leading-tight text-gray-900">{{name}}</h3>\
            <dl class="space-y-2">\
              <dd class="flex text-base text-gray-600">\
                <span class="ml-0">\
                  <span class="block">{{street}}</span>\
                  <span class="block">{{city}}, {{province}} {{postal_code}}</span>\
                  <span class="block">{{country}}</span>\
                </span>\
              </dd>\
            </dl>\
            <dl class="space-y-4">\
              <dd class="flex text-base text-gray-600">\
                <div class="flex w-full items-center justify-between">\
                  <div class="info flex">\
                    <span class="ml-0"><a href="tel:{{phone}}">{{phone}}</a></span>\
                  </div>\
                </div>\
              </dd>\
            </dl>\
          </div>\
          <div class="relative border-t mt-2">\
            <a class="mt-2 btn btn-primary w-full justify-center" href="{{href}}">View {{type}}</a>\
          </div>\
        </div>\
      </div>\
    ';

    const myLatLng = { lat: 0, lng: 0 };
    const map = new google.maps.Map(this.mapTarget, {
      zoom: 10,
      center: myLatLng,
      scrollwheel: false,
      streetViewControl: false,
      mapId: this.gmidValue
    });
    // do not zoom all the way in if we use fitBounds and only one marker
    google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
      if (this.getZoom() > 15) {
        this.setZoom(15);
      }
    });
    
    // map.setTilt(50);
    this.addMarkers(location_json, map, bounds, infoWindow, template);
    // Center the map to fit all markers on the screen
    map.fitBounds(bounds);
  }

  addMarkers(location_json, map, bounds, infoWindow, template) {
    console.log('adding markers')
    location_json.forEach((location, i) => {
      if (location['latitude'] === null) { return; }
      // Place each marker on the map 
      var position = new google.maps.LatLng(parseFloat(location['latitude']), parseFloat(location['longitude']));
      // console.log(position)
      bounds.extend(position);
      var marker = new google.maps.Marker({
        position: position,
        map,
        title: location['name'],
      });

      // Add info window to marker    
      google.maps.event.addListener(marker, 'click', (function (marker, i) {
        return function () {

          var popupHtml = template.replace(/{{name}}/g, location['name'])
            .replace(/{{type}}/g, location['type'])
            .replace(/{{street}}/g, location['street'])
            .replace(/{{city}}/g, location['city'])
            .replace(/{{province}}/g, location['province'])
            .replace(/{{postal_code}}/g, location['postal_code'])
            .replace(/{{country}}/g, location['country'])
            .replace(/{{phone}}/g, location['phone'])
            // .replace(/{{berths}}/g, location['berths'])
            .replace(/{{href}}/g, location['href']);
          infoWindow.setContent(popupHtml);
          infoWindow.open(map, marker);
        }
      })(marker, i));
    })
  }
}