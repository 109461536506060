import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="maps"
export default class extends Controller {

  static targets = [
    "field", 
    "map", 
    "latitude", 
    "longitude", 
    "address", 
    "street", 
    "city", 
    "province", 
    "postalcode", 
    "country"
]

connect() {
    console.log("Maps controller connected.", this.element)
    // console.log("this.fieldTarget", this.fieldTarget)
    // console.log("this.mapTarget", this.mapTarget)
    // console.log("this.addressTarget", this.addressTarget)
    // console.log("this.streetTarget", this.streetTarget)
    // console.log("this.cityTarget", this.cityTarget)
    // console.log("this.provinceTarget", this.provinceTarget)
    // console.log("this.postalcodeTarget", this.postalcodeTarget)
    // console.log("this.countryTarget", this.countryTarget)
    // console.log("this.latitudeTarget", this.latitudeTarget)
    // console.log("this.longitudeTarget", this.longitudeTarget)

    if (typeof (google) != "undefined") {
      this.initializeMap()
    }
  }

  initializeMap() {
    this.map()
    this.marker()
    this.autocomplete()
    console.log('init')
  }

  map() {
    if (this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(
          this.latitudeTarget.value,
          this.longitudeTarget.value
        ),
        zoom: 17,
        scrollwheel: false,
      })
    }
    return this._map
  }

  marker() {
    if (this._marker == undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0, 0)
      })
      let mapLocation = {
        lat: parseFloat(this.latitudeTarget.value),
        lng: parseFloat(this.longitudeTarget.value)
      }
      this._marker.setPosition(mapLocation)
      this._marker.setVisible(true)
    }
    return this._marker
  }

  autocomplete() {
    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
      this._autocomplete.bindTo('bounds', this.map())
      this._autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
      this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
    }
    return this._autocomplete
  }

  locationChanged() {
    let place = this.autocomplete().getPlace()

    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }
    console.log('place', place)
    this.map().fitBounds(place.geometry.viewport)
    this.map().setCenter(place.geometry.location)
    this.marker().setPosition(place.geometry.location)
    this.marker().setVisible(true)

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
    let components = place.address_components

    // console.log(components)
    let streetNumber = components.find(o => o.types.includes('street_number'))?.long_name
    let streetName = components.find(o => o.types.includes('route'))?.long_name
    let localityName = components.find(o => o.types.includes('locality'))?.long_name
    let sublocalityName = components.find(o => o.types.includes('sublocality'))?.long_name
    let provinceCode = components.find(o => o.types.includes('administrative_area_level_1'))?.short_name
    let postalCode = components.find(o => o.types.includes('postal_code'))?.long_name
    let countryCode = components.find(o => o.types.includes('country'))?.short_name
    
    let streetAddress = [streetNumber, streetName].filter(Boolean).join(' ')
    let cityName = localityName || sublocalityName
    
    // let fullAddress = streetAddress? + ', ' + cityName + ', ' + provinceCode + '  ' + postalCode? + ', ' + countryCode
    let fullAddressTokens = [streetAddress, cityName, provinceCode, postalCode, countryCode]
    // console.log('fullAddressTokens', fullAddressTokens)
    let fullAddress = fullAddressTokens.filter(Boolean).join(', ')
    // console.log('fullAddress', fullAddress)
    
    // console.log(streetNumber)
    // console.log(streetName)

    // console.log(streetAddress)
    // console.log(cityName)
    // console.log(localityName)
    // console.log(provinceCode)
    // console.log(postalCode)
    // console.log(countryCode)

    this.addressTarget.value = fullAddress 
    this.streetTarget.value = streetAddress || ''
    this.cityTarget.value = cityName
    this.provinceTarget.value = provinceCode || ''
    this.postalcodeTarget.value = postalCode || ''
    this.countryTarget.value = countryCode



  }

  preventSubmit(e) {
    if (e.key == "Enter") { e.preventDefault() }
  }

}
